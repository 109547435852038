
















































import { Component, Vue } from 'vue-property-decorator'
import get from 'lodash.get'
import { blogDraftKeys, useDeleteDraft, useGetDrafts } from '@/hooks/blogDraft'
import { QueryClient, useQueryClient } from 'vue-query'

@Component({
  components: {},
  setup() {
    const queryClient = useQueryClient()
    const {
      data: draftData,
      fetchNextPage: nextDraftPage,
      isFetchingNextPage: isNextDraftFetching,
      isFetching: isDraftLoading,
      hasNextPage: hasNextDraft,
    } = useGetDrafts()

    const { mutateAsync: delDraft, isLoading: isDelDraftLoading } = useDeleteDraft()

    return {
      queryClient,
      draftData,
      nextDraftPage,
      isNextDraftFetching,
      isDraftLoading,
      hasNextDraft,
      delDraft,
      isDelDraftLoading,
    }
  },
})
export default class AIBlogDraft extends Vue {
  draftData!: any
  nextDraftPage!: any
  isNextDraftFetching!: boolean
  isDraftLoading!: boolean
  hasNextDraft!: boolean
  isDelDraftLoading!: boolean
  delDraft!: any

  queryClient!: QueryClient

  get drafts() {
    return get(this, 'draftData', [])
  }

  editDraft(draft: any) {
    this.$gtag.event('edit-draft', {
      event_category: 'User Interaction',
      event_label: 'Edit draft',
    })
    this.$router.push(`/home/blog/${draft.id}?method=URL`)
  }
  async deleteBlogDraft(draftId: string) {
    await this.delDraft(draftId)
    this.queryClient.invalidateQueries(blogDraftKeys.GET_DRAFT)
  }
}
