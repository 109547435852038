var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "flex mh500 flex-column mt4 pb4 justify-center items-center silver pa3 tc bg-adori-light-gray ba b--adori-gray br2 br-bottom",
    },
    [
      _c("div", { staticClass: "light-gray ph3 pv4 f4 db b-color" }, [
        _vm._v("Lets create some videos!"),
      ]),
      _c("div", { staticClass: "flex w-100 ph5" }, [
        _c(
          "div",
          {
            staticClass:
              "w-50 relative ba ma2 b--adori-gray br3 pa4 white mh-150 pointer bg-adori-medium-gray noselect card-b",
            on: {
              click: function ($event) {
                return _vm.handleMethod("URL")
              },
            },
          },
          [
            _c("i", { staticClass: "material-icons light-gray f2 v-mid pa1" }, [
              _vm._v(" link "),
            ]),
            _c("div", { staticClass: "word-wrap pt2 b" }, [
              _vm._v("Insert Blog Url"),
            ]),
            _c("div", { staticClass: "f6 gray pt3 mb2" }, [
              _vm._v("Paste your blog link and our AI will auto detect scenes"),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "w-50 relative ba ma2 b--adori-gray br3 pa4 white mh-150 pointer bg-adori-medium-gray noselect card-b",
            on: {
              click: function ($event) {
                return _vm.handleMethod("TEXT")
              },
            },
          },
          [
            _c("i", { staticClass: "material-icons light-gray f2 v-mid pa1" }, [
              _vm._v(" content_paste "),
            ]),
            _c("div", { staticClass: "word-wrap pt2 b" }, [
              _vm._v("Copy Paste Text"),
            ]),
            _c("div", { staticClass: "f6 gray pt3 mb2" }, [
              _vm._v(
                "Paste text from any website i.e blog, article, news etc."
              ),
            ]),
          ]
        ),
      ]),
      _c("div", { staticClass: "flex w-100 mb4 ph5" }, [
        _c(
          "div",
          {
            staticClass:
              "w-50 relative ba ma2 b--adori-gray br3 pa4 white mh-190 pointer bg-adori-medium-gray noselect card-b",
            on: {
              click: function ($event) {
                return _vm.handleMethod("FILE")
              },
            },
          },
          [
            _c("i", { staticClass: "material-icons light-gray f2 v-mid pa1" }, [
              _vm._v(" text_snippet "),
            ]),
            _c("div", { staticClass: "word-wrap pt2 b" }, [
              _vm._v("Upload .pdf or .txt file"),
            ]),
            _c("div", { staticClass: "f6 gray pt3 mb2" }, [
              _vm._v("Upload pdf having images or plain text file"),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "w-50 relative ba ma2 b--adori-gray br3 pa4 white mh-190 pointer bg-adori-medium-gray noselect card-b",
            on: {
              click: function ($event) {
                return _vm.handleMethod("IDEA")
              },
            },
          },
          [
            _c("i", { staticClass: "material-icons light-gray f2 v-mid pa1" }, [
              _vm._v(" tips_and_updates "),
            ]),
            _c("div", { staticClass: "word-wrap pt2 b" }, [
              _vm._v("Idea to Video"),
            ]),
            _c("div", { staticClass: "f6 gray pt3 mb2" }, [
              _vm._v("Use AI to brainstorm ideas and write script"),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }